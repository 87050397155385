import React from 'react';
import PropTypes from 'prop-types';
import colors from 'theme/config/colors';
import SVG from './base/SVG';

const Tooltip = ({ color, ...props }) => (
    <SVG viewBox="0 0 16 16" fill="none" {...props}>
        <path d="M8.52 4.896V3.348H6.912V4.896H8.52ZM8.484 12V5.76H6.96V12H8.484Z" fill={color} />
        <circle cx="8" cy="8" r="7.5" stroke={color} />
    </SVG>
);

Tooltip.propTypes = {
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

Tooltip.defaultProps = {
    color: colors.textGrey,
    height: '16px',
    width: '16px',
};

export default Tooltip;
