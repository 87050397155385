import { above } from 'core-web/util/mediaqueries';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from 'theme/components/icons/Tooltip';
import colors from 'theme/config/colors';
import styled from 'theme/libs/styled';

const Span = styled('span', {
    shouldForwardProp: (prop) => ['hasBackOrderFlag', 'hasPreOrderFlag', 'inStock', 'isBookable'].indexOf(prop) === -1,
})`
    display: block;
    position: relative;

    svg {
        vertical-align: middle;
    }

    &:before {
        content: '';
        display: inline-block;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        margin-right: 4px;
        background-color: ${colors.outOfStock};
        margin-bottom: 1px;

        ${above.xl} {
            width: 8px;
            height: 8px;
            margin-right: 8px;
        }
    }
    &.bookable:before {
        background-color: ${colors.isBookable};
    }
    &.bookable-arrow:before {
        position: absolute;
        white-space: pre;
        display: inline;
        margin-right: 8px;
        width: 0;
        height: 0;
        border-radius: 2px;
        border-top: 7px solid transparent;
        border-bottom: 7px solid transparent;

        border-left: 7px solid ${colors.inStock};
        background-color: transparent;
    }

    &.in-stock:before {
        background-color: ${colors.inStock};
    }

    &.hide::before {
        background-color: transparent;
    }

    &.with-icon:before {
        display: none;
    }

    &.no-text:before {
        display: none;
    }
`;

const Text = styled('span')`
    &.bookable-arrow {
        margin-left: 12px;
    }
`;

const Button = styled('button')`
    margin-left: 8px;
`;

const PreBookInfo = styled('div')`
    margin-top: 2px;
    padding-left: 10px;

    ${above.xl} {
        padding-left: 16px;
    }
`;

const PreBookInfoText = styled('span')`
    font-size: 11px;
    line-height: 1em;

    ${above.lg} {
        font-size: 12px;
    }
`;

const StockStatus = ({
    isBookable,
    isOutForSeason,
    isVariant,
    showBookingInfoText,
    onHandFilter,
    height,
    stock,
    leadTime,
    locale,
    nextDelivery,
    render,
    prefix,
    suffix,
    DeliveryIcon,
    isSearchItem,
    onlyPickUpInStore,
    ...rest
    // eslint-disable-next-line sonarjs/cognitive-complexity -- temporary
}) => {
    const [showInfoText, setShowInfoText] = useState(false);
    const { t } = useTranslation();
    const inStock = stock > 0 && onHandFilter !== 'OUT_OF_STOCK';
    const appName = process.env.REACT_APP_NAME || '';

    const useLeadtimePrefix =
        appName === 'duab' && (locale === 'da_DK' || locale === 'nb_NO') && leadTime > 0 && stock > 0;
    const ignorePrefix = appName === 'duab' && inStock && leadTime > 0 && locale === 'fi_FI';
    const selectedPrefix = useLeadtimePrefix ? `${t('stock.leadtime_prefix')} - ` : prefix;

    const useArrowIcon = appName === 'dogger' || appName === 'maskinklippet' || appName === 'frishop';
    let translatedText;
    let type;

    if (isVariant && !inStock) {
        return <Span className="no-text" height={height} />;
    }
    if (nextDelivery && !inStock) {
        if (isBookable) {
            translatedText = isSearchItem
                ? t('stock.pre_order_without_date', { date: nextDelivery })
                : t('stock.pre_order_in_stock', { date: nextDelivery });
            type = null;
        } else {
            translatedText = isSearchItem
                ? t('stock.expected_without_date', { date: nextDelivery })
                : t('stock.expected_in_stock', { date: nextDelivery });
            type = null;
        }
    } else if (inStock && leadTime > 0) {
        let translationKey = `stock.lead_time_${leadTime > 5 ? 'weeks' : 'days'}`;
        let translationOption;
        type = 'incoming';

        if (leadTime <= 5) {
            translationOption = `${leadTime}-${leadTime + 2}`;
        } else if (leadTime > 5 && leadTime < 14) {
            translationOption = '1-2';
        } else if (leadTime >= 14 && leadTime < 21) {
            translationOption = '2-3';
        } else if (leadTime >= 21 && leadTime < 28) {
            translationOption = '3-4';
        } else if (leadTime >= 28 && leadTime < 42) {
            translationOption = '4-6';
        } else if (leadTime >= 42 && leadTime < 56) {
            translationOption = '6-8';
        } else if (leadTime >= 56 && leadTime < 84) {
            translationOption = '8-12';
        } else {
            translationKey = 'stock.contact';
        }

        translatedText = t(translationKey, { leadTime: translationOption });
    } else {
        let translationSubKey = 'out_of_stock';

        if (inStock) {
            translationSubKey = 'in_stock';
        } else if (isOutForSeason) {
            translationSubKey = 'out_for_season';
        } else if (isBookable) {
            translationSubKey = 'pre_order';
        }

        const translationKey = `stock.${translationSubKey}`;

        translatedText = t(translationKey);
        type = inStock ? 'inStock' : null;
    }
    const showIcon = type === 'incoming' && !!DeliveryIcon;

    let wrapperStockClassName = '';

    if (showIcon) {
        wrapperStockClassName += 'in-stock with-icon';
    } else if (type) {
        wrapperStockClassName += 'in-stock';
    }

    return render ? (
        render(translatedText)
    ) : (
        <Span
            className={`${wrapperStockClassName} ${
                !inStock && isBookable ? 'bookable' : ''
            } ${useArrowIcon && !inStock && isBookable ? 'bookable-arrow' : ''}
            ${onlyPickUpInStore ? 'hide' : ''}
            `}
            {...rest}
            inStock={inStock}
            isBookable={isBookable}
        >
            {!onlyPickUpInStore && showIcon && <DeliveryIcon />}

            <Text className={`${useArrowIcon && !inStock && isBookable ? 'bookable-arrow' : ''}`}>
                {onlyPickUpInStore || ignorePrefix ? null : selectedPrefix}
                {onlyPickUpInStore ? t('stock.only_in_store') : translatedText}
            </Text>
            {suffix}
            {showBookingInfoText && !inStock && isBookable && (
                <Button onClick={() => setShowInfoText((open) => !open)}>
                    <Tooltip />
                </Button>
            )}
            {showInfoText && (
                <PreBookInfo>
                    <PreBookInfoText>{t('stock.book_info')}</PreBookInfoText>
                </PreBookInfo>
            )}
        </Span>
    );
};

StockStatus.propTypes = {
    DeliveryIcon: PropTypes.node,
    hasBackOrderFlag: PropTypes.bool,
    hasPreOrderFlag: PropTypes.bool,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    isBookable: PropTypes.bool,
    isOutForSeason: PropTypes.bool,
    isSearchItem: PropTypes.bool,
    isVariant: PropTypes.bool,
    leadTime: PropTypes.number,
    locale: PropTypes.string,
    nextDelivery: PropTypes.string,
    onlyPickUpInStore: PropTypes.bool,
    onHandFilter: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    prefix: PropTypes.string,
    render: PropTypes.func,
    showBookingInfoText: PropTypes.bool,
    stock: PropTypes.number.isRequired,
    suffix: PropTypes.string,
};

StockStatus.defaultProps = {
    DeliveryIcon: null,
    hasBackOrderFlag: null,
    hasPreOrderFlag: null,
    height: null,
    isBookable: false,
    isOutForSeason: false,
    isSearchItem: false,
    isVariant: false,
    leadTime: null,
    locale: null,
    nextDelivery: null,
    onlyPickUpInStore: false,
    onHandFilter: '',
    prefix: '',
    render: null,
    showBookingInfoText: false,
    suffix: '',
};

export default StockStatus;
