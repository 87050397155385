import { getFlag } from './getFlag';

const getDropshipInfo = (flag: string[] | string, flagObject: object, product: any, locale: string) => {
    const productFlags = product?.format?.flags || [];
    const hasGlobalFlag = getFlag(flag, productFlags);

    const hasMarketFlag = getFlag(flagObject[locale as keyof typeof flagObject], productFlags);
    return !!(hasGlobalFlag || hasMarketFlag);
};

export default getDropshipInfo;
