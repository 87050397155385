// https://stackoverflow.com/a/62590340

export const numberOfDaysInclusive = (start: Date, end: Date) =>
    1 + Math.round((end.getTime() - start.getTime()) / (24 * 3600 * 1000));

export const numberOfWeekends = (start: Date, end: Date) => {
    const days = numberOfDaysInclusive(start, end);
    const sundays = Math.floor((days + ((start.getDay() + 6) % 7)) / 7);
    return 2 * sundays + (end.getDay() === 6 ? 1 : 0) - (start.getDay() === 0 ? 1 : 0);
};

export const numberOfWeekdays = (start: Date, end: Date) =>
    numberOfDaysInclusive(start, end) - numberOfWeekends(start, end);
