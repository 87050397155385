import FormatPrice from 'core-web/components/FormatPrice';
import { injectModel } from 'core-web/state';
import { showRecommendedPrice } from 'core-web/util/showRecommendedPrice';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from 'theme/components/icons/Tooltip';
import colors from 'theme/config/colors';
import styled from 'theme/libs/styled';

const Wrapper = styled('div', {
    shouldForwardProp: (prop) => ['fontStyle', 'mainColor'].indexOf(prop) === -1,
})`
    span {
        font: ${({ fontStyle }) => (fontStyle ? '600 24px/1.4em Inter' : '600 16px/1em Inter')};
        color: ${colors.darkOrange};
        line-height: 16px;

        &.basket-item {
            display: inline-block;
            margin-top: 4px;
            margin-left: 8px;
            margin-right: 8px;
        }

        &.not-in-basket-history-price {
            display: inline-block;
            font-weight: 400;
            text-decoration: line-through;
            margin-top: 4px;
            margin-left: 8px;
            color: ${({ mainColor }) => (mainColor.length ? colors[mainColor] : colors.black)};
        }

        &.basket-item-list-price {
            color: ${colors.darkOrange};
            display: inline-block;
            margin-top: 4px;
            margin-left: 8px;
            margin-right: 8px;
            line-height: 16px;
            margin-right: 8px;
        }

        &.basket-item-history-price {
            display: inline-block;
            font-weight: 400;
            text-decoration: line-through;
            margin-top: 4px;
            margin-left: 8px;
            color: ${({ mainColor }) => (mainColor.length ? colors[mainColor] : colors.black)};
        }

        &:only-of-type {
            font: ${({ fontStyle }) => (fontStyle ? '600 24px/1.4em Inter' : '600 16px/1em Inter')};
            color: ${colors.darkOrange};
            text-decoration: none;
            line-height: 16px;
            margin-right: 8px;
        }
    }
`;

const Button = styled('button')`
    position: relative;
    top: -1px;
    padding-left: 8px;
    text-align: left;
    vertical-align: baseline;
`;

const Price = ({
    application,
    priceExVat,
    recommendedPriceExVat,
    vatRate,
    isCheckoutUpsell,
    textAlign,
    isBasketItem,
    fontStyle,
    setShowPriceHistory,
    hideButton,
    isSearchItem,
    mainColor,
}) => {
    const { t } = useTranslation();

    const { hide_recommended: hideRecommendedPrice, rec_price_settings: recPriceSettings } =
        application.config?.options?.pricing || {};
    const showRecPrice = showRecommendedPrice(priceExVat, recommendedPriceExVat, {
        hideRecommendedPrice,
        recPriceSettings,
    });

    const formatProps = {
        isRounded: true,
        separateWith: ' ',
        render: (price) => t('price_with_currency', { price }),
    };

    const handleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setShowPriceHistory((open) => !open);
    };

    return (
        <Wrapper
            pt={(isCheckoutUpsell || showRecPrice) && '4px'}
            ml={isBasketItem && '8px'}
            textAlign={textAlign}
            fontStyle={fontStyle}
            mainColor={mainColor}
        >
            {!isBasketItem && (
                <span className="not-in-basket">
                    <FormatPrice {...formatProps} vatRate={vatRate} price={priceExVat} />
                </span>
            )}
            {showRecPrice &&
                !hideButton &&
                (isBasketItem ? (
                    <span className="basket-item-history-price">
                        <Button pr="8px" onClick={(e) => handleClick(e)}>
                            <Tooltip />
                        </Button>
                        <FormatPrice pr="8px" {...formatProps} price={recommendedPriceExVat} vatRate={vatRate} />
                    </span>
                ) : (
                    <span className="not-in-basket-history-price">
                        <FormatPrice {...formatProps} price={recommendedPriceExVat} vatRate={vatRate} />
                        {!isSearchItem && (
                            <Button pl="8px" onClick={(e) => handleClick(e)}>
                                <Tooltip />
                            </Button>
                        )}
                    </span>
                ))}
            {isBasketItem && (
                <span className="basket-item-list-price">
                    <FormatPrice {...formatProps} vatRate={vatRate} price={priceExVat} />
                </span>
            )}
        </Wrapper>
    );
};

Price.propTypes = {
    application: PropTypes.object.isRequired,
    fontStyle: PropTypes.bool,
    hideButton: PropTypes.bool,
    isBasketItem: PropTypes.bool,
    isCheckoutUpsell: PropTypes.bool,
    isSearchItem: PropTypes.bool,
    mainColor: PropTypes.string,
    priceExVat: PropTypes.number.isRequired,
    recommendedPriceExVat: PropTypes.number,
    setShowPriceHistory: PropTypes.func.isRequired,
    textAlign: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    vatRate: PropTypes.number,
};

Price.defaultProps = {
    fontStyle: false,
    hideButton: false,
    isBasketItem: false,
    isCheckoutUpsell: false,
    isSearchItem: false,
    mainColor: 'black',
    recommendedPriceExVat: 0,
    textAlign: 'left',
    vatRate: 1,
};

export default injectModel('application')(Price);
