import PropTypes from 'prop-types';
import { injectModels } from 'core-web/state';
import toFixedCurrency from 'core-web/util/toFixedCurrency';

const memo = {};

/**
 * Returns formatted price that auto adds vat
 *
 * @param {boolean} [isRounded=false] - If price should be rounded or not.
 * @param {string} [separateWith] - String that will separate number, ex: 1.000 if '.', 1 000 if ' '
 * @param {number} price - Price excluding VAT
 * @param {func} [render] - Render func with formatted price as prop
 * @param {number} [vatRate=1] Price is multiplied with this if showVat is set.
 * @return {string}
 *
 * @example
 *
 *      <FormatPrice
 *          isRounded
 *          separateWith="."
 *          price={totalPricing.priceExVat}
 *          vatRate={totalPricing.vatRate}
 *          render=(price => t('price_with_currency', { price }))
 *      />
 */

const FormatPrice = ({
    application,
    isRounded,
    separateWith,
    price,
    priceIncVat,
    products: { showVat },
    render,
    vatRate,
}) => {
    const key = `${+isRounded}${+showVat}${separateWith || ''}_${price}_${vatRate}`;
    const appName = process.env.REACT_APP_NAME || '';

    const isEUR = application.currency.code === 'EUR';
    const isDKK = application.currency.code === 'DKK';

    if (isDKK) {
        separateWith = '.';
    }

    if (!memo[key]) {
        let p = showVat ? priceIncVat || price * vatRate : price;

        if (isEUR) {
            separateWith = '\u00A0';
            p = toFixedCurrency(p, 2);
        } else if (p % 1 !== 0 || appName === 'duab') {
            p = isRounded ? Math.round(p) : toFixedCurrency(p, 2);
        }

        if (separateWith) {
            const splitPrice = p.toString().split('.');
            splitPrice[0] = splitPrice[0].replace(/\B(?=(\d{3})+(?!\d))/g, separateWith);
            p = splitPrice.join(isEUR ? ',' : '.');
        }

        memo[key] = p;
    }

    const memoizedPrice = memo[key];

    return render ? render(memoizedPrice) : memoizedPrice;
};

FormatPrice.propTypes = {
    giftCardAmount: PropTypes.number,
    isRounded: PropTypes.bool,
    price: PropTypes.number.isRequired,
    products: PropTypes.shape({
        showVat: PropTypes.bool.isRequired,
    }),
    render: PropTypes.func,
    separateWith: PropTypes.string,
    vatRate: PropTypes.number,
};

FormatPrice.defaultProps = {
    isRounded: false,
    render: null,
    separateWith: null,
    vatRate: 1,
};

export default injectModels(['products', 'application'])(FormatPrice);
